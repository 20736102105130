import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const AlertTitle = makeShortcode("AlertTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Before You Begin`}</h2>
    <p>{`This document assumes you have initialized and started up a `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` instance using the steps in `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started"
      }}>{`Getting Started`}</a>{`.`}</p>
    <h2>{`Table of Contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#working-with-x-509-certificates"
        }}>{`Working With X.509 Certificates`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#working-with-ssh-certificates"
        }}>{`Working With SSH Certificates`}</a></li>
    </ul>
    <h2>{`Working With X.509 Certificates`}</h2>
    <p>{`Let's perform some basic operations on X.509 certificates:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#get-a-certificate"
        }}>{`Get a Certificate`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sign-a-certificate-signing-request-csr"
        }}>{`Sign a Certificate Signing Request (CSR)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#issue-a-certificate-using-a-single-use-ca-token"
        }}>{`Issue a certificate using a single-use CA token`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#renew-a-certificate"
        }}>{`Renew a Certificate`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adjust-certificate-lifetimes"
        }}>{`Adjust Certificate Lifetimes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#revoke-a-certificate"
        }}>{`Revoke a Certificate`}</a></li>
    </ul>
    <h3>{`Get a Certificate`}</h3>
    <p>{`Once you have a certificate authority up and running, the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/certificate"
      }}><inlineCode parentName="a">{`step ca certificate`}</inlineCode></a>{` command is a one-step option for generating a private key and obtaining a signed certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca certificate svc.example.com svc.crt svc.key
✔ Provisioner: carl@smallstep.com (JWK) [kid: JxCvTLC67zKCOi-yyMoHpO5vAj_MnXs80PR0nh7IjUg]
✔ Please enter the password to decrypt the provisioner key:
✔ CA: https://127.0.0.1:4337
✔ Certificate: svc.crt
✔ Private Key: svc.key
`}</code></pre>
    <p>{`The positional arguments in the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/certificate"
      }}><inlineCode parentName="a">{`step ca certificate`}</inlineCode></a>{` command indicate the name you would like associated with the certificate (in this case, the DNS host name of a service) and the output filenames for the certificate and private key.`}</p>
    <p>{`You can check your work using `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/certificate/inspect"
      }}><inlineCode parentName="a">{`step certificate inspect`}</inlineCode></a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate inspect svc.crt --short
X.509v3 TLS Certificate (ECDSA P-256) [Serial: 7720...1576]
  Subject:     svc.example.com
  Issuer:      Smallstep Intermediate CA
  Provisioner: carl@smallstep.com [ID: JxCv...IjUg]
  Valid from:  2020-09-22T00:59:37Z
          to:  2020-09-23T01:00:37Z
`}</code></pre>
    <Alert severity="info" mdxType="Alert">
    <AlertTitle mdxType="AlertTitle">
        Why does the certificate only last for ONE DAY!?
    </AlertTitle>
    <div>
        When it comes to certificate lifetimes, <a href="/docs/step-ca/renewal">more is not always more</a>. A shorter validity period limits the downside risk of the private key being stolen. And since all certificates need to be renewed eventually, you may as well automate it and do it often. However, the 24 hour default TLS certificate lifetime is rather arbitrary. To change the default, see <a href="#adjust-certificate-lifetimes">Adjust Certificate Lifetimes</a> below.
    </div>
    </Alert>
    <h3>{`Sign a `}<a parentName="h3" {...{
        "href": "https://smallstep.com/blog/everything-pki/#certificate-signing-requests"
      }}>{`Certificate Signing Request (CSR)`}</a></h3>
    <p>{`Sometimes it's useful to separate enrollment into two steps:`}</p>
    <ul>
      <li parentName="ul">{`Generating a private key and a certificate request (CSR) file`}</li>
      <li parentName="ul">{`Asking the CA to sign the CSR and return a certificate`}</li>
    </ul>
    <p>{`This is necessary if you want to generate a private key in a place that can't reach the CA directly.`}</p>
    <p>{`Let's make a CSR, using `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/certificate/create"
      }}><inlineCode parentName="a">{`step certificate create --csr`}</inlineCode></a>{`, then we'll use `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/sign"
      }}><inlineCode parentName="a">{`step ca sign`}</inlineCode></a>{` to get a signed certificate from the CA:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate create --csr foo.example.com foo.csr foo.key
Please enter the password to encrypt the private key:

$ step ca sign foo.csr foo.crt
✔ Provisioner: carl@smallstep.com (JWK) [kid: yWa7WGfoSt9yJ0OZCndrvR_m65jzDriY7mhPz094fdw]
✔ Please enter the password to decrypt the provisioner key: ...
✔ CA: https://127.0.0.1:4337
✔ Certificate: foo.crt
`}</code></pre>
    <h3>{`Issue a certificate using a single-use CA token`}</h3>
    <p>{`Occasionally it's useful to separate enrollment process even further, into three steps:`}</p>
    <ul>
      <li parentName="ul">{`Generating a private key and a CSR`}</li>
      <li parentName="ul">{`Generating a single-use, signed, short-lived CA authentication token (a JWT)`}</li>
      <li parentName="ul">{`Using the token and CSR to ask the CA for a signed certificate`}</li>
    </ul>
    <p>{`Each step can happen in a different context,
and the first two steps can happen without contacting the CA.`}</p>
    <p>{`This is useful for delegating CA authentication to a third party.`}</p>
    <p>{`For example, you may want to generate a CA token on a host,
and inject the token into a Docker container.
This way, the container can create its own private key and get a certificate,
but it doesn't hold any long-lived CA credentials.`}</p>
    <p>{`Under the hood, many of `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`'s `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/certificate-authority-core-concepts/#provisioners"
      }}>{`certificate provisioners`}</a>{` accept a Javascript Web Token (JWT) along with a CSR to authenticate certificate requests:`}</p>
    <ul>
      <li parentName="ul">{`The JWK provisioner accepts JWTs signed using a JavaScript Web Key whose public key is configured in the CA.`}</li>
      <li parentName="ul">{`The OIDC provisioner accepts JWTs signed by an identity provider via a single sign-on flow.`}</li>
      <li parentName="ul">{`The X5C provisioner accepts JWTs signed using an X509 certificate private key whose root is configured in the CA.`}</li>
    </ul>
    <p>{`In the following example, the CA generates a token for the client who has the JWK's encrypted private key password:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ TOKEN=$(step ca token localhost)
✔ Provisioner: carl@smallstep.com (JWK) [kid: kvPj79hrAnrQywrVy-oFmye4foHq1rdUg55nxsuCvkI]
✔ Please enter the password to decrypt the provisioner key:
$ echo $TOKEN | step crypto jwt inspect --insecure
{
  "header": {
    "alg": "ES256",
    "kid": "kvPj79hrAnrQywrVy-oFmye4foHq1rdUg55nxsuCvkI",
    "typ": "JWT"
  },
  "payload": {
    "aud": "https://localhost:8443/1.0/sign",
    "exp": 1634667515,
    "iat": 1634667215,
    "iss": "carl@smallstep.com",
    "jti": "ffa63c06b0f3ccb0de554711836042a877e32a0322df42d617c6da59af65ec7d",
    "nbf": 1634667215,
    "sans": [
      "localhost"
    ],
    "sha": "256538bb3b338289f80308ef286b00d4a4ac87b87fa3fcaff2234f2943572bb6",
    "sub": "localhost"
  },
  "signature": "g8S-1Mrb9U3l3CbTRE-Mvcy2m2I-M2b_9KXj04SnqSxyMVDRzvnpoW3XYtlGgCcIexo5gQpOpe0QrkdZuKwhUQ"
}
`}</code></pre>
    <p>{`This JWT expires in 5 minutes. `}<a parentName="p" {...{
        "href": "https://jwt.io/#debugger-io?token=eyJhbGciOiJFUzI1NiIsImtpZCI6Imt2UGo3OWhyQW5yUXl3clZ5LW9GbXllNGZvSHExcmRVZzU1bnhzdUN2a0kiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOiJodHRwczovL2xvY2FsaG9zdDo4NDQzLzEuMC9zaWduIiwiZXhwIjoxNjM0NjY3NTE1LCJpYXQiOjE2MzQ2NjcyMTUsImlzcyI6ImNhcmxAc21hbGxzdGVwLmNvbSIsImp0aSI6ImZmYTYzYzA2YjBmM2NjYjBkZTU1NDcxMTgzNjA0MmE4NzdlMzJhMDMyMmRmNDJkNjE3YzZkYTU5YWY2NWVjN2QiLCJuYmYiOjE2MzQ2NjcyMTUsInNhbnMiOlsibG9jYWxob3N0Il0sInNoYSI6IjI1NjUzOGJiM2IzMzgyODlmODAzMDhlZjI4NmIwMGQ0YTRhYzg3Yjg3ZmEzZmNhZmYyMjM0ZjI5NDM1NzJiYjYiLCJzdWIiOiJsb2NhbGhvc3QifQ.g8S-1Mrb9U3l3CbTRE-Mvcy2m2I-M2b_9KXj04SnqSxyMVDRzvnpoW3XYtlGgCcIexo5gQpOpe0QrkdZuKwhUQ&publicKey=%7B%0A%20%20%22use%22%3A%20%22sig%22%2C%0A%20%20%22kty%22%3A%20%22EC%22%2C%0A%20%20%22kid%22%3A%20%22kvPj79hrAnrQywrVy-oFmye4foHq1rdUg55nxsuCvkI%22%2C%0A%20%20%22crv%22%3A%20%22P-256%22%2C%0A%20%20%22alg%22%3A%20%22ES256%22%2C%0A%20%20%22x%22%3A%20%22qgJNrHeWBxHpGKns1VqkQCTQYIHOzoJm5khaqGXAbdw%22%2C%0A%20%20%22y%22%3A%20%22Sb4RL5_S5ZAKPZMAS8a0eye1uJHDJWFiCfXFx04mYcc%22%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%7D"
      }}>{`The raw `}<inlineCode parentName="a">{`$TOKEN`}</inlineCode></a>{` is a series of base64-encoded values.`}</p>
    <p>{`Next, generate the CSR:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate create --csr localhost localhost.csr localhost.key
Please enter the password to encrypt the private key:
Your certificate signing request has been saved in localhost.csr.
Your private key has been saved in localhost.key.
`}</code></pre>
    <p>{`And finally, get the CSR signed, using the token:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca sign --token $TOKEN localhost.csr localhost.crt
✔ CA: https://localhost:8443
✔ Certificate: localhost.crt
$ step certificate inspect localhost.crt --short
X.509v3 TLS Certificate (ECDSA P-256) [Serial: 1459...0708]
  Subject:     localhost
  Issuer:      Example Intermediate CA
  Provisioner: carl@smallstep.com [ID: kvPj...CvkI]
  Valid from:  2021-10-19T18:14:55Z
          to:  2021-10-20T18:15:55Z
`}</code></pre>
    <h3>{`Renew a Certificate`}</h3>
    <p>{`Certificate renewal is easy, and is authenticated using the existing private key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ step ca renew foo.crt foo.key
✔ Would you like to overwrite foo.crt [y/n]: y
Your certificate has been saved in foo.crt.
`}</code></pre>
    <p>{`When it comes time to renew your certificate, do not dawdle: Once a certificate expires, the CA will not renew it. To avoid catastrophe, you should `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/renewal"
      }}>{`set up automated renewals`}</a>{` for any certificate that always needs to be valid. Our automation methods will attempt renewal at around two-thirds of a certificate's lifetime.`}</p>
    <p>{`Note that because `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/renew"
      }}><inlineCode parentName="a">{`step ca renew`}</inlineCode></a>{` uses mutual TLS authentication, it can only renew client certificates (certificates that are marked with "Client Authentication" key usage). Any other certificate must be replaced before expiry instead.`}</p>
    <h3>{`Adjust Certificate Lifetimes`}</h3>
    <p>{`The 24 hour default validity window for certificates from `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` is arbitrary. Depending on your threat model and use context, you may want much shorter or much longer certificate lifetimes:`}</p>
    <ul>
      <li parentName="ul">{`Server certificates and service account certificates typically are longer lived, lasting 1-90 days.`}</li>
      <li parentName="ul">{`Client certificates for humans typically are shorter lived and could expire after a few minutes or up to a month.`}</li>
    </ul>
    <p>{`A TLS certificate only needs to be valid at the moment the connection is established. So, a client certificate allowing access to a sensitive database might only need to be valid for five minutes, and the client can stay connected for as long as they need to complete the task at hand.`}</p>
    <p>{`You can adjust the certificate `}<inlineCode parentName="p">{`not-before`}</inlineCode>{` and `}<inlineCode parentName="p">{`not-after`}</inlineCode>{` parameters when requesting a certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`# This certificate will be valid for five minutes:
$ step ca certificate localhost localhost.crt localhost.key --not-after=5m

# This certificate will be valid starting 5 minutes from now, until 10 days from now:
$ step ca certificate localhost localhost.crt localhost.key --not-before=5m --not-after=240h
`}</code></pre>
    <p>{`Note that default maximum certificate duration is 24 hours. To adjust the global default, minimum, and maximum certificate durations for your CA, add a `}<inlineCode parentName="p">{`claims`}</inlineCode>{` section to the `}<inlineCode parentName="p">{`$(step path)/config/ca.json`}</inlineCode>{` configuration file, under `}<inlineCode parentName="p">{`"authority"`}</inlineCode>{`, with the following keys:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`"claims": {
    "minTLSCertDuration": "5m",
    "maxTLSCertDuration": "24h",
    "defaultTLSCertDuration": "24h"
}
`}</code></pre>
    <p>{`You can also set default, minimum, and maximum certificate durations on each provisioner.
See `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/configuration#basic-configuration-options"
      }}>{`Basic Configuration Options`}</a>{` for more details.`}</p>
    <h3>{`Revoke a Certificate`}</h3>
    <p>{`There is no way to "un-issue" a certificate. Once a certificate has been signed and distributed by the CA, it's valid until it expires.`}</p>
    <p>{`However, you can ask the CA to block the renewal of a certificate. This is called `}<em parentName="p">{`passive revocation`}</em>{`.`}</p>
    <p>{`Let's revoke the `}<inlineCode parentName="p">{`svc.crt`}</inlineCode>{` certificate we created earlier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ step ca revoke --cert svc.crt --key svc.key
✔ CA: https://localhost:4337
Certificate with Serial Number 30671613121311574910895916201205874495 has been revoked.
`}</code></pre>
    <p>{`For more on this topic, read `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/revocation"
      }}>{`All About Certificate Revocation`}</a>{`.`}</p>
    <h2>{`Working With SSH Certificates`}</h2>
    <p>{`In this section we'll go over the basics of issuing and renewing SSH certificates for users and hosts.`}</p>
    <h3>{`Background`}</h3>
    <p>{`SSH certificates are not X.509 certificates.
Instead, they follow an `}<a parentName="p" {...{
        "href": "https://github.com/openssh/openssh-portable/blob/master/PROTOCOL.certkeys"
      }}>{`SSH-specific certificate format`}</a>{`.`}</p>
    <p>{`Mercifully, they are a lot simpler than X.509:`}</p>
    <ul>
      <li parentName="ul">{`SSH supports only User certificates and Host certificates.`}</li>
      <li parentName="ul">{`There are no certificate chains in SSH.`}</li>
      <li parentName="ul">{`Instead of a root CA certificate, an SSH CA is represented as a trusted public key.`}</li>
      <li parentName="ul">{`An SSH CA is just like any other SSH public key, except it's prepended with a special `}<inlineCode parentName="li">{`@cert-authority`}</inlineCode>{` annotation when used in a `}<inlineCode parentName="li">{`known_hosts`}</inlineCode>{` file.`}</li>
    </ul>
    <p>{`When both the user and the host use certificates in a connection, you will have mutual authentication:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`The host authenticates the user`}</strong>{`: When configured to trust the User CA key, a host delegates user identity to the SSH CA. When a user presents their certificate to a host during the SSH handshake, the host will trust it if it's signed by the User CA key, and it will alow any listed certificate principals (usernames) to sign in.`}</li>
      <li parentName="ul"><strong parentName="li">{`The user authenticates the host`}</strong>{`: When configured to trust the Host CA key, clients delegate host identity to the CA. When a host presents its certificate to a user during the SSH handshake, the user will trust it if it's signed by the host CA key.`}</li>
    </ul>
    <p>{`When `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
      }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a>{` is run with `}<inlineCode parentName="p">{`--ssh`}</inlineCode>{`, it creates two SSH CA key pairs: one for the host CA, and one for the user CA. The user CA key signs SSH user certificates, and the host CA key signs SSH host certificates.`}</p>
    <h3>{`Requirements`}</h3>
    <ul>
      <li parentName="ul">{`You will need a `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` Certificate Authority `}<strong parentName="li">{`with SSH support enabled`}</strong>{`. Create one by running `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
        }}><inlineCode parentName="a">{`step ca init --ssh`}</inlineCode></a>{`.`}</li>
      <li parentName="ul">{`To see SSH certificates in action, set up a host or VM running SSHD. In this example, our host is running Ubuntu 18.04 LTS, and it `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started#accessing-step-ca-remotely"
        }}>{`has been configured to access our CA remotely`}</a>{`.`}</li>
    </ul>
    <h3>{`Getting a host to authenticate users`}</h3>
    <p>{`First let's delegate user authentication to the SSH CA.`}</p>
    <h4>{`1. Get your host to trust your SSH user CA`}</h4>
    <p>{`On your host, once you've `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started#accessing-step-ca-remotely"
      }}>{`bootstrapped your CA configuration`}</a>{`, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ step ssh config --roots > /path/to/ssh_user_key.pub
`}</code></pre>
    <p>{`Add this to key your SSHD configuration. On your host, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ cat <<EOF >> /etc/ssh/sshd_config
# This is the CA's public key for authenticating user certificates:
TrustedUserCAKeys /path/to/ssh_user_key.pub
EOF
`}</code></pre>
    <p>{`Restart SSHD. Your host will now trust any user certificate issued by the CA. On Ubuntu 18.04 LTS, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ systemctl restart ssh.service
`}</code></pre>
    <h4>{`2. Issue an SSH user certificate and test your connection`}</h4>
    <p>{`Let's create an SSH user certificate for the user `}<inlineCode parentName="p">{`alice`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ step ssh certificate alice@smallstep.com id_ecdsa
✔ Provisioner: carl@smallstep.com (JWK) [kid: yWa7WGfoSt9yJ0OZCndrvR_m65jzDriY7mhPz094fdw]
✔ Please enter the password to decrypt the provisioner key:
✔ CA: https://127.0.0.1:4337
Please enter the password to encrypt the private key:
✔ Private Key: id_ecdsa
✔ Public Key: id_ecdsa.pub
✔ Certificate: id_ecdsa-cert.pub
✔ SSH Agent: yes
`}</code></pre>
    <p>{`The CA issued a private key, a public key, and an SSH certificate, and `}<inlineCode parentName="p">{`step`}</inlineCode>{` added the certificate and private key to our local SSH agent. We only need the private key and certificate to use SSH certificate authentication. Let's inspect the certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ cat id_ecdsa-cert.pub | tail -1 | step ssh inspect
-:
        Type: ecdsa-sha2-nistp256-cert-v01@openssh.com user certificate
        Public key: ECDSA-CERT SHA256:EPVTWfml136JG5FNR5xkFz7PRhUvuMUWzRXyQ+2zJfE
        Signing CA: ECDSA SHA256:yrTW8Ej/0kzGebLRvXIVFclXfA1dF/9VRiGjRnRcXl4
        Key ID: "alice@smallstep.com"
        Serial: 7887351871112993341
        Valid: from 2020-09-22T11:27:56 to 2020-09-23T03:28:56
        Principals:
                alice
                alice@smallstep.com
        Critical Options: (none)
        Extensions:
                permit-pty
                permit-user-rc
                permit-X11-forwarding
                permit-agent-forwarding
                permit-port-forwarding
`}</code></pre>
    <p>{`By default, when using SSH certificates, SSHD will allow you to connect as any of the listed principals. If the list of principals is empty, SSHD will allow you to authenticate as `}<em parentName="p">{`anyone`}</em>{` on the host.`}</p>
    <p>{`Now you have a certificate for your user, try to login on your host.`}</p>
    <h3>{`Getting SSH clients to trust hosts`}</h3>
    <p>{`Hosts can have SSH certificates, too, instead of the typical host public key pair. Host certificates allow clients who trust the host CA to avoid the trust on first use prompt.`}</p>
    <h4>{`1. Issue a certificate for your host`}</h4>
    <p>{`On your host, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ step ssh certificate --host internal.example.com ssh_host_ecdsa_key
✔ Provisioner: carl@smallstep.com (JWK) [kid: yWa7WGfoSt9yJ0OZCndrvR_m65jzDriY7mhPz094fdw]
✔ Please enter the password to decrypt the provisioner key:
✔ CA: https://127.0.0.1:4337
Please enter the password to encrypt the private key:
✔ Private Key: ssh_host_ecdsa_key
✔ Public Key: ssh_host_ecdsa_key.pub
✔ Certificate: ssh_host_ecdsa_key-cert.pub
`}</code></pre>
    <p>{`Let's see what you created:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ cat ssh_host_ecdsa_key-cert.pub | step ssh inspect
-:
        Type: ecdsa-sha2-nistp256-cert-v01@openssh.com host certificate
        Public key: ECDSA-CERT SHA256:Dc+Mzy43RzKoIaLrI8GzeHcQLbIa3hQ3mhirIDjbu0s
        Signing CA: ECDSA SHA256:IG7xVPz9kC6PwUTDDRvn+HIy1xUf/zllPo0InlSfaTg
        Key ID: "internal.example.com"
        Serial: 14283430004353679661
        Valid: from 2020-09-22T11:34:40 to 2020-10-22T11:35:40
        Principals:
                internal.example.com
        Critical Options: (none)
        Extensions: (none)
`}</code></pre>
    <p>{`By default, host certificates expire in a month. `}</p>
    <h4>{`2. Install your host key and certificate`}</h4>
    <p>{`If you want your host to use this key, move the files to `}<inlineCode parentName="p">{`/etc/ssh`}</inlineCode>{` and add the following to your SSHD configuration. On your host:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ mv ssh_host_ecdsa_key ssh_host_ecdsa_key-cert.pub /etc/ssh
$ cat <<EOF >> /etc/ssh/sshd_config
# This is our host private key and certificate:
HostKey /etc/ssh/ssh_host_ecdsa_key
HostCertificate /etc/ssh/ssh_host_ecdsa_key-cert.pub
EOF
`}</code></pre>
    <h4>{`3. Automate host key rotation`}</h4>
    <p>{`Because the host certificate expires in a month, you'll also need to set up automated renewal of the certificate. Let's add a weekly renewal `}<inlineCode parentName="p">{`cron`}</inlineCode>{` script that runs `}<inlineCode parentName="p">{`step ssh renew`}</inlineCode>{`, on your host:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ cat <<EOF > /etc/cron.weekly/rotate-ssh-certificate
#!/bin/sh
export STEPPATH=/root/.step
cd /etc/ssh && step ssh renew ssh_host_ecdsa_key-cert.pub ssh_host_ecdsa_key --force 2> /dev/null
exit 0
EOF
chmod 755 /etc/cron.weekly/rotate-ssh-certificate
`}</code></pre>
    <h4>{`4. Configure SSH clients to trust your host CA`}</h4>
    <p>{`To get your SSH clients to trust host certificates issued by your CA, you'll need to add the host CA key to SSH's `}<inlineCode parentName="p">{`.ssh/known_hosts`}</inlineCode>{` file.`}</p>
    <p>{`To view the host key, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ step ssh config --host --roots
ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNTYAAAAIbmlzdHAyNTYAAABBBFM2fTh3Utg3eGMqy2mJqa48Qsr7onZfOtIpJRNvvd71+eBUHBQdhnGmo2NPksNV3GxEuUZKAjZMlNv5c13XvfY=
`}</code></pre>
    <p>{`Add this to the `}<inlineCode parentName="p">{`.ssh/known_hosts`}</inlineCode>{` file on your SSH client, prepending `}<inlineCode parentName="p">{`@cert-authority * `}</inlineCode>{` to it to mark it as a Certificate Authority, eg:`}</p>
    <pre><code parentName="pre" {...{}}>{`@cert-authority * ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNTYAAAAIbmlzdHAyNTYAAABBBFM2fTh3Utg3eGMqy2mJqa48Qsr7onZfOtIpJRNvvd71+eBUHBQdhnGmo2NPksNV3GxEuUZKAjZMlNv5c13XvfY=
`}</code></pre>
    <p>{`Your SSH client will now trust any host with a valid certificate signed by the CA.`}</p>
    <h3>{`Test your connection`}</h3>
    <p>{`You're all done. Now test your SSH connection.`}</p>
    <h2>{`Next Steps`}</h2>
    <ul>
      <li parentName="ul">{`Learn to automate certificate management in your PKI. `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/acme-basics"
        }}>{`ACME Basics`}</a>{` introduces the ACME protocol for certificate automation, and includes a tutorial for setting it up with `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`See `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners"
        }}>{`Provisioners`}</a>{`, `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/certificate-authority-server-production"
        }}>{`Production Considerations`}</a>{`, and our `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/configuration"
        }}>{`Configuration Guide`}</a>{` to learn more about tailoring `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` to your infrastructure.`}</li>
      <li parentName="ul">{`Run `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca"
        }}><inlineCode parentName="a">{`step ca help`}</inlineCode></a>{` or `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ssh"
        }}><inlineCode parentName="a">{`step ssh help`}</inlineCode></a>{` for many more flags and examples.`}</li>
      <li parentName="ul">{`Our `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/tutorials"
        }}>{`Tutorials`}</a>{` can guide you through more complex scenarios for using `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` in various contexts and workflows.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      